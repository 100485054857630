/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'


const Top = () => {
    const aboutTopImages = useStaticQuery(graphql`
    query AboutTopImages {
      first: file(absolutePath: {regex: "/about-top-image-01.jpg/"}) {
        childImageSharp {
          sizes(maxWidth: 790) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      second: file(absolutePath: {regex: "/about-top-image-02.jpg/"}) {
        childImageSharp {
          sizes(maxWidth: 790) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      third: file(absolutePath: {regex: "/about-top-image-03.jpg/"}) {
        childImageSharp {
          sizes(maxWidth: 790) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)
    return (
      <Container className="introduction content-wrap">
        <Row className="sp-5">
          <Col md="8" lg="7" xl="6">
            <h1>We're a new kind of consulting company.</h1>
            <p>
              We listen, we challenge, we work collaboratively, we make things
              -&nbsp;
              <Link className="styled__Link-lfEBUk iUBhrC" to="/services/#how-deliver-we">
                faster than you&apos;d believe.
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="about-image-grid">
          	<div>
                <Image
                    sizes={aboutTopImages.first.childImageSharp.sizes}
                alt="We listen, we challenge, we work collaboratively" />
          	</div>
          	<div>
                <Image
                    sizes={aboutTopImages.second.childImageSharp.sizes}
                alt="We're a new kind of consulting company" />
          	</div>
          	<div>
              <Image
                    sizes={aboutTopImages.third.childImageSharp.sizes}
                alt="We make things - faster than you'd believe" />
          	</div>
          </Col>
        </Row>
      </Container>
    )
}

export default Top
