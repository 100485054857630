/* eslint-disable */
import React from 'react'
import Layout from '../components/Layout'
// import Testimonials from '../components/Testimonials'

import {
  Topabout,
  Facts,
  Careers,
  Review,
  Culture,
} from '../components/About'

import Headinfo from '../components/Headinfo'
import Gettouch from '../components/Gettouch'
// import Projecthead from '../components/Services/Projecthead'
interface IAbout {
  
}

const Contact: React.FC<IAbout> = () => {
  return (
    <div className="body greybackground">
      <Layout>
        <Topabout />
        <Review />
        <Facts />
        <Culture />
        <Careers />
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  )
}

export default Contact


