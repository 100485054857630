/* eslint-disable */
import React from 'react'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

const AboutCareers = () => {
  const AboutCareersImages = useStaticQuery(graphql`
    query AboutCareersImages {
      first: file(absolutePath: {regex: "/servicesimageleft.jpg/"}) {
        childImageSharp {
          sizes(maxWidth: 1464) {
             ...GatsbyImageSharpSizes
          }
        }
      }
      second: file(absolutePath: {regex: "/77021616_198454671192299_1004824348712202613_n.jpg/"}) {
        childImageSharp {
          sizes(maxWidth: 640) {
             ...GatsbyImageSharpSizes
          }
        }
      }
      third: file(absolutePath: {regex: "/AntonVideoLoop.jpg/"}) {
        childImageSharp {
          sizes(maxWidth: 640) {
             ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)
  return (
    <Container className="spb-10">
        <Row className="sp-8">
          <Col md="5">
            <h3>Join us</h3>
          </Col>
          <Col md="5">
              <p>Do more than design and build incredible products. Help ship them, too. Because for us, work is only as good as its impact in the wild.</p>
              <Link to="/careers" className="iUBhrC colorblack mt-5">View current openings
              <svg
                className="styled__Arrow-d87459-0 bQLhSh"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </Link>
          </Col>
        </Row>
        <Row>
            <Col className="careers-image-grid">
            <div>
                         <Image
                            sizes={AboutCareersImages.first.childImageSharp.sizes}
                         />
            </div>
            <div>
                        <Image
                            sizes={AboutCareersImages.second.childImageSharp.sizes}
                         />
            </div>
            <div>
                        <Image
                            sizes={AboutCareersImages.third.childImageSharp.sizes}
                        />
            </div>
          </Col>
        </Row>
    </Container>
  )
}

export default AboutCareers
