/* eslint-disable */
import React from 'react'

import './styled.css'
class Review extends React.Component {
  render() {
    return (
      <div className="spb-8 container testimonial">
        <div className="row">
          <div className="col-12">
            <h2>
              The secret to their success is their ability to assemble talented teams of people that care. I think of Urbian as a critical member of my team, not some outsourced function.
            </h2>
            <span>Bronwyn Johnson, Product Owner Liberty Direct</span>
          </div>
        </div>
      </div>
    )
  }
}

export default Review
